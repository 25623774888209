<template>
  <div v-if="vModel.participants.length" class="flex flex-col gap-4">
    <div class="flex flex-row items-center gap-4 py-2.5">
      <div class="w-6">
        <UiIcon name="user-group" class="text-black-70" />
      </div>
      <div class="text-body-2 px-2">
        {{ vModel.participants.join(', ')?.toString() }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Activity } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: Activity
}

const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)
</script>

<style scoped></style>
