<template>
  <div v-if="vModel.participants.length || vModel.location || vModel.meet_url" class="flex flex-col gap-4">
    <div v-if="vModel.participants.length" class="flex flex-row items-start gap-4">
      <div class="w-6">
        <UiIcon name="user-group" class="text-black-70" />
      </div>
      <div class="text-body-2 px-2">
        {{ vModel.participants.join(', ')?.toString() }}
      </div>
    </div>
    <div v-if="vModel.location" class="flex flex-row items-start gap-4">
      <div class="w-6">
        <UiIcon name="location" class="text-black-70" />
      </div>
      <div class="text-body-2 px-2">
        {{ vModel.location }}
      </div>
    </div>
    <div v-if="vModel.meet_url" class="flex flex-row items-center gap-4">
      <div class="w-6">
        <UiIcon name="videocall" class="text-black-70" />
      </div>
      <div class="flex flex-row items-center gap-2">
        <UiButtonBase
          id="join_meeting"
          type="secondary"
          @click="
            navigateTo(vModel.meet_url, {
              external: true,
              open: {
                target: '_blank',
              },
            })
          "
          >Join Google meeting
        </UiButtonBase>
        <UiButtonBase id="copy_url" icon type="secondary" class="!border-black-20" @click="copyLink">
          <UiIcon name="copy" class="text-black-60"></UiIcon>
        </UiButtonBase>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Activity } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: Activity
}

const props = defineProps<Props>()

const vModel = useVModel(props, 'modelValue', emits)

const copyLink = () => {
  if (props.modelValue.meet_url) navigator.clipboard.writeText(props.modelValue.meet_url as string)
}
</script>

<style scoped></style>
